export const surahData = [
    {
        name: "Al-Fatihah",
        arabicName: "الفاتحة",
        totalAyahs: 7,
        juz: [{ number: 1, startAyah: 1, endAyah: 7 }]
    },
    {
        name: "Al-Baqarah",
        arabicName: "البقرة",
        totalAyahs: 286,
        juz: [
            { number: 1, startAyah: 1, endAyah: 141 },
            { number: 2, startAyah: 142, endAyah: 286 }
        ]
    },
    {
        name: "Al-Imran",
        arabicName: "آل عمران",
        totalAyahs: 200,
        juz: [
            { number: 3, startAyah: 1, endAyah: 92 },
            { number: 4, startAyah: 93, endAyah: 200 }
        ]
    },
    {
        name: "An-Nisa",
        arabicName: "النساء",
        totalAyahs: 176,
        juz: [
            { number: 4, startAyah: 1, endAyah: 23 },
            { number: 5, startAyah: 24, endAyah: 176 }
        ]
    },
    {
        name: "Al-Ma'idah",
        arabicName: "المائدة",
        totalAyahs: 120,
        juz: [
            { number: 6, startAyah: 1, endAyah: 81 },
            { number: 7, startAyah: 82, endAyah: 120 }
        ]
    },
    {
        name: "Al-An'am",
        arabicName: "الأنعام",
        totalAyahs: 165,
        juz: [
            { number: 7, startAyah: 1, endAyah: 110 },
            { number: 8, startAyah: 111, endAyah: 165 }
        ]
    },
    {
        name: "Al-A'raf",
        arabicName: "الأعراف",
        totalAyahs: 206,
        juz: [
            { number: 8, startAyah: 1, endAyah: 87 },
            { number: 9, startAyah: 88, endAyah: 206 }
        ]
    },
    {
        name: "Al-Anfal",
        arabicName: "الأنفال",
        totalAyahs: 75,
        juz: [
            { number: 9, startAyah: 1, endAyah: 40 },
            { number: 10, startAyah: 41, endAyah: 75 }
        ]
    },
    {
        name: "At-Tawbah",
        arabicName: "التوبة",
        totalAyahs: 129,
        juz: [
            { number: 10, startAyah: 1, endAyah: 93 },
            { number: 11, startAyah: 94, endAyah: 129 }
        ]
    },
    {
        name: "Yunus",
        arabicName: "يونس",
        totalAyahs: 109,
        juz: [{ number: 11, startAyah: 1, endAyah: 109 }]
    },
    {
        name: "Hud",
        arabicName: "هود",
        totalAyahs: 123,
        juz: [{ number: 11, startAyah: 1, endAyah: 123 }]
    },
    {
        name: "Yusuf",
        arabicName: "يوسف",
        totalAyahs: 111,
        juz: [{ number: 12, startAyah: 1, endAyah: 111 }]
    },
    {
        name: "Ar-Ra'd",
        arabicName: "الرعد",
        totalAyahs: 43,
        juz: [{ number: 13, startAyah: 1, endAyah: 43 }]
    },
    {
        name: "Ibrahim",
        arabicName: "إبراهيم",
        totalAyahs: 52,
        juz: [{ number: 13, startAyah: 1, endAyah: 52 }]
    },
    {
        name: "Al-Hijr",
        arabicName: "الحجر",
        totalAyahs: 99,
        juz: [{ number: 14, startAyah: 1, endAyah: 99 }]
    },
    {
        name: "An-Nahl",
        arabicName: "النحل",
        totalAyahs: 128,
        juz: [{ number: 14, startAyah: 1, endAyah: 128 }]
    },
    {
        name: "Al-Isra",
        arabicName: "الإسراء",
        totalAyahs: 111,
        juz: [{ number: 15, startAyah: 1, endAyah: 111 }]
    },
    {
        name: "Al-Kahf",
        arabicName: "الكهف",
        totalAyahs: 110,
        juz: [
            { number: 15, startAyah: 1, endAyah: 74 },
            { number: 16, startAyah: 75, endAyah: 110 }
        ]
    },


    {
        name: "Maryam",
        arabicName: "مريم",
        totalAyahs: 98,
        juz: [{ number: 16, startAyah: 1, endAyah: 98 }]
    },
    {
        name: "Taha",
        arabicName: "طه",
        totalAyahs: 135,
        juz: [{ number: 16, startAyah: 1, endAyah: 135 }]
    },
    {
        name: "Al-Anbiya",
        arabicName: "الأنبياء",
        totalAyahs: 112,
        juz: [{ number: 17, startAyah: 1, endAyah: 112 }]
    },
    {
        name: "Al-Hajj",
        arabicName: "الحج",
        totalAyahs: 78,
        juz: [{ number: 17, startAyah: 1, endAyah: 78 }]
    },
    {
        name: "Al-Mu'minun",
        arabicName: "المؤمنون",
        totalAyahs: 118,
        juz: [{ number: 18, startAyah: 1, endAyah: 118 }]
    },
    {
        name: "An-Nur",
        arabicName: "النور",
        totalAyahs: 64,
        juz: [{ number: 18, startAyah: 1, endAyah: 64 }]
    },
    {
        name: "Al-Furqan",
        arabicName: "الفرقان",
        totalAyahs: 77,
        juz: [{ number: 19, startAyah: 1, endAyah: 77 }]
    },
    {
        name: "Ash-Shu'ara",
        arabicName: "الشعراء",
        totalAyahs: 227,
        juz: [{ number: 19, startAyah: 1, endAyah: 227 }]
    },
    {
        name: "An-Naml",
        arabicName: "النمل",
        totalAyahs: 93,
        juz: [{ number: 20, startAyah: 1, endAyah: 93 }]
    },
    {
        name: "Al-Qasas",
        arabicName: "القصص",
        totalAyahs: 88,
        juz: [{ number: 20, startAyah: 1, endAyah: 88 }]
    },
    {
        name: "Al-Ankabut",
        arabicName: "العنكبوت",
        totalAyahs: 69,
        juz: [{ number: 20, startAyah: 1, endAyah: 45 },
        { number: 21, startAyah: 46, endAyah: 69 }]
    },
    {
        name: "Ar-Rum",
        arabicName: "الروم",
        totalAyahs: 60,
        juz: [{ number: 21, startAyah: 1, endAyah: 60 }]
    },
    {
        name: "Luqman",
        arabicName: "لقمان",
        totalAyahs: 34,
        juz: [{ number: 21, startAyah: 1, endAyah: 34 }]
    },
    {
        name: "As-Sajdah",
        arabicName: "السجدة",
        totalAyahs: 30,
        juz: [{ number: 21, startAyah: 1, endAyah: 30 }]
    },
    {
        name: "Al-Ahzab",
        arabicName: "الأحزاب",
        totalAyahs: 73,
        juz: [{ number: 21, startAyah: 1, endAyah: 30 },
        { number: 22, startAyah: 31, endAyah: 73 }]
    },
    {
        name: "Saba",
        arabicName: "سبأ",
        totalAyahs: 54,
        juz: [{ number: 22, startAyah: 1, endAyah: 54 }]
    },
    {
        name: "Fatir",
        arabicName: "فاطر",
        totalAyahs: 45,
        juz: [{ number: 22, startAyah: 1, endAyah: 45 }]
    },
    {
        name: "Yasin",
        arabicName: "يس",
        totalAyahs: 83,
        juz: [{ number: 23, startAyah: 1, endAyah: 83 }]
    },
    {
        name: "As-Saffat",
        arabicName: "الصافات",
        totalAyahs: 182,
        juz: [{ number: 23, startAyah: 1, endAyah: 182 }]
    },
    {
        name: "Sad",
        arabicName: "ص",
        totalAyahs: 88,
        juz: [{ number: 23, startAyah: 1, endAyah: 88 }]
    },
    {
        name: "Az-Zumar",
        arabicName: "الزمر",
        totalAyahs: 75,
        juz: [{ number: 24, startAyah: 1, endAyah: 75 }]
    },
    {
        name: "Ghafir",
        arabicName: "غافر",
        totalAyahs: 85,
        juz: [{ number: 24, startAyah: 1, endAyah: 85 }]
    },
    {
        name: "Fussilat",
        arabicName: "فصلت",
        totalAyahs: 54,
        juz: [{ number: 24, startAyah: 1, endAyah: 54 }]
    },
    {
        name: "Ash-Shura",
        arabicName: "الشورى",
        totalAyahs: 53,
        juz: [{ number: 25, startAyah: 1, endAyah: 53 }]
    },
    {
        name: "Az-Zukhruf",
        arabicName: "الزخرف",
        totalAyahs: 89,
        juz: [{ number: 25, startAyah: 1, endAyah: 89 }]
    },
    {
        name: "Ad-Dukhan",
        arabicName: "الدخان",
        totalAyahs: 59,
        juz: [{ number: 25, startAyah: 1, endAyah: 59 }]
    },
    {
        name: "Al-Jathiya",
        arabicName: "الجاثية",
        totalAyahs: 37,
        juz: [{ number: 25, startAyah: 1, endAyah: 37 }]
    },


    {
        name: "Al-Ahqaf",
        arabicName: "الأحقاف",
        totalAyahs: 35,
        juz: [{ number: 26, startAyah: 1, endAyah: 35 }]
    },
    {
        name: "Muhammad",
        arabicName: "محمد",
        totalAyahs: 38,
        juz: [{ number: 26, startAyah: 1, endAyah: 38 }]
    },
    {
        name: "Al-Fath",
        arabicName: "الفتح",
        totalAyahs: 29,
        juz: [{ number: 26, startAyah: 1, endAyah: 29 }]
    },
    {
        name: "Al-Hujurat",
        arabicName: "الحجرات",
        totalAyahs: 18,
        juz: [{ number: 26, startAyah: 1, endAyah: 18 }]
    },
    {
        name: "Qaf",
        arabicName: "ق",
        totalAyahs: 45,
        juz: [{ number: 26, startAyah: 1, endAyah: 45 }]
    },
    {
        name: "Adh-Dhariyat",
        arabicName: "الذاريات",
        totalAyahs: 60,
        juz: [{ number: 27, startAyah: 1, endAyah: 60 }]
    },
    {
        name: "At-Tur",
        arabicName: "الطور",
        totalAyahs: 49,
        juz: [{ number: 27, startAyah: 1, endAyah: 49 }]
    },
    {
        name: "An-Najm",
        arabicName: "النجم",
        totalAyahs: 62,
        juz: [{ number: 27, startAyah: 1, endAyah: 62 }]
    },
    {
        name: "Al-Qamar",
        arabicName: "القمر",
        totalAyahs: 55,
        juz: [{ number: 27, startAyah: 1, endAyah: 55 }]
    },
    {
        name: "Ar-Rahman",
        arabicName: "الرحمن",
        totalAyahs: 78,
        juz: [{ number: 27, startAyah: 1, endAyah: 78 }]
    },
    {
        name: "Al-Waqia",
        arabicName: "الواقعة",
        totalAyahs: 96,
        juz: [{ number: 27, startAyah: 1, endAyah: 96 }]
    },
    {
        name: "Al-Hadid",
        arabicName: "الحديد",
        totalAyahs: 29,
        juz: [{ number: 27, startAyah: 1, endAyah: 29 }]
    },
    {
        name: "Al-Mujadila",
        arabicName: "المجادلة",
        totalAyahs: 22,
        juz: [{ number: 28, startAyah: 1, endAyah: 22 }]
    },
    {
        name: "Al-Hashr",
        arabicName: "الحشر",
        totalAyahs: 24,
        juz: [{ number: 28, startAyah: 1, endAyah: 24 }]
    },
    {
        name: "Al-Mumtahina",
        arabicName: "الممتحنة",
        totalAyahs: 13,
        juz: [{ number: 28, startAyah: 1, endAyah: 13 }]
    },
    {
        name: "As-Saff",
        arabicName: "الصف",
        totalAyahs: 14,
        juz: [{ number: 28, startAyah: 1, endAyah: 14 }]
    },
    {
        name: "Al-Jumuah",
        arabicName: "الجمعة",
        totalAyahs: 11,
        juz: [{ number: 28, startAyah: 1, endAyah: 11 }]
    },
    {
        name: "Al-Munafiqun",
        arabicName: "المنافقون",
        totalAyahs: 11,
        juz: [{ number: 28, startAyah: 1, endAyah: 11 }]
    },
    {
        name: "At-Taghabun",
        arabicName: "التغابن",
        totalAyahs: 18,
        juz: [{ number: 28, startAyah: 1, endAyah: 18 }]
    },
    {
        name: "At-Talaq",
        arabicName: "الطلاق",
        totalAyahs: 12,
        juz: [{ number: 28, startAyah: 1, endAyah: 12 }]
    },
    {
        name: "At-Tahrim",
        arabicName: "التحريم",
        totalAyahs: 12,
        juz: [{ number: 28, startAyah: 1, endAyah: 12 }]
    },
    {
        name: "Al-Mulk",
        arabicName: "الملك",
        totalAyahs: 30,
        juz: [{ number: 29, startAyah: 1, endAyah: 30 }]
    },
    {
        name: "Al-Qalam",
        arabicName: "القلم",
        totalAyahs: 52,
        juz: [{ number: 29, startAyah: 1, endAyah: 52 }]
    },
    {
        name: "Al-Haqqah",
        arabicName: "الحاقة",
        totalAyahs: 52,
        juz: [{ number: 29, startAyah: 1, endAyah: 52 }]
    },
    {
        name: "Al-Ma'arij",
        arabicName: "المعارج",
        totalAyahs: 44,
        juz: [{ number: 29, startAyah: 1, endAyah: 44 }]
    },
    {
        name: "Nuh",
        arabicName: "نوح",
        totalAyahs: 28,
        juz: [{ number: 29, startAyah: 1, endAyah: 28 }]
    },
    {
        name: "Al-Jinn",
        arabicName: "الجن",
        totalAyahs: 28,
        juz: [{ number: 29, startAyah: 1, endAyah: 28 }]
    },
    {
        name: "Al-Muzzammil",
        arabicName: "المزمل",
        totalAyahs: 20,
        juz: [{ number: 29, startAyah: 1, endAyah: 20 }]
    },
    {
        name: "Al-Muddathir",
        arabicName: "المدثر",
        totalAyahs: 56,
        juz: [{ number: 29, startAyah: 1, endAyah: 56 }]
    },
    {
        name: "Al-Qiyamah",
        arabicName: "القيامة",
        totalAyahs: 40,
        juz: [{ number: 29, startAyah: 1, endAyah: 40 }]
    },
    {
        name: "Al-Insan",
        arabicName: "الإنسان",
        totalAyahs: 31,
        juz: [{ number: 29, startAyah: 1, endAyah: 31 }]
    },
    {
        name: "Al-Mursalat",
        arabicName: "المرسلات",
        totalAyahs: 50,
        juz: [{ number: 29, startAyah: 1, endAyah: 50 }]
    },
    {
        name: "An-Naba",
        arabicName: "النبأ",
        totalAyahs: 40,
        juz: [{ number: 30, startAyah: 1, endAyah: 40 }]
    },
    {
        name: "An-Naziat",
        arabicName: "النازعات",
        totalAyahs: 46,
        juz: [{ number: 30, startAyah: 1, endAyah: 46 }]
    },
    {
        name: "Abasa",
        arabicName: "عبس",
        totalAyahs: 42,
        juz: [{ number: 30, startAyah: 1, endAyah: 42 }]
    },
    {
        name: "At-Takwir",
        arabicName: "التكوير",
        totalAyahs: 29,
        juz: [{ number: 30, startAyah: 1, endAyah: 29 }]
    },
    {
        name: "Al-Infitar",
        arabicName: "الإنفطار",
        totalAyahs: 19,
        juz: [{ number: 30, startAyah: 1, endAyah: 19 }]
    },
    {
        name: "Al-Mutaffifin",
        arabicName: "المطففين",
        totalAyahs: 36,
        juz: [{ number: 30, startAyah: 1, endAyah: 36 }]
    },
    {
        name: "Al-Inshiqaq",
        arabicName: "الإنشقاق",
        totalAyahs: 25,
        juz: [{ number: 30, startAyah: 1, endAyah: 25 }]
    },
    {
        name: "Al-Buruj",
        arabicName: "البروج",
        totalAyahs: 22,
        juz: [{ number: 30, startAyah: 1, endAyah: 22 }]
    },
    {
        name: "At-Tariq",
        arabicName: "الطارق",
        totalAyahs: 17,
        juz: [{ number: 30, startAyah: 1, endAyah: 17 }]
    },
    {
        name: "Al-Ala",
        arabicName: "الأعلى",
        totalAyahs: 19,
        juz: [{ number: 30, startAyah: 1, endAyah: 19 }]
    },
    {
        name: "Al-Ghashiyah",
        arabicName: "الغاشية",
        totalAyahs: 26,
        juz: [{ number: 30, startAyah: 1, endAyah: 26 }]
    },
    {
        name: "Al-Fajr",
        arabicName: "الفجر",
        totalAyahs: 30,
        juz: [{ number: 30, startAyah: 1, endAyah: 30 }]
    },
    {
        name: "Al-Balad",
        arabicName: "البلد",
        totalAyahs: 20,
        juz: [{ number: 30, startAyah: 1, endAyah: 20 }]
    },
    {
        name: "Ash-Shams",
        arabicName: "الشمس",
        totalAyahs: 15,
        juz: [{ number: 30, startAyah: 1, endAyah: 15 }]
    },
    {
        name: "Al-Lail",
        arabicName: "الليل",
        totalAyahs: 21,
        juz: [{ number: 30, startAyah: 1, endAyah: 21 }]
    },
    {
        name: "Ad-Duhaa",
        arabicName: "الضحى",
        totalAyahs: 11,
        juz: [{ number: 30, startAyah: 1, endAyah: 11 }]
    },
    {
        name: "Ash-Sharh",
        arabicName: "الشرح",
        totalAyahs: 8,
        juz: [{ number: 30, startAyah: 1, endAyah: 8 }]
    },
    {
        name: "At-Tin",
        arabicName: "التين",
        totalAyahs: 8,
        juz: [{ number: 30, startAyah: 1, endAyah: 8 }]
    },
    {
        name: "Al-Alaq",
        arabicName: "العلق",
        totalAyahs: 19,
        juz: [{ number: 30, startAyah: 1, endAyah: 19 }]
    },
    {
        name: "Al-Qadr",
        arabicName: "القدر",
        totalAyahs: 5,
        juz: [{ number: 30, startAyah: 1, endAyah: 5 }]
    },
    {
        name: "Al-Bayyina",
        arabicName: "البينة",
        totalAyahs: 8,
        juz: [{ number: 30, startAyah: 1, endAyah: 8 }]
    },
    {
        name: "Az-Zalzalah",
        arabicName: "الزلزلة",
        totalAyahs: 8,
        juz: [{ number: 30, startAyah: 1, endAyah: 8 }]
    },
    {
        name: "Al-Adiyat",
        arabicName: "العاديات",
        totalAyahs: 11,
        juz: [{ number: 30, startAyah: 1, endAyah: 11 }]
    },
    {
        name: "Al-Qariah",
        arabicName: "القارعة",
        totalAyahs: 11,
        juz: [{ number: 30, startAyah: 1, endAyah: 11 }]
    },
    {
        name: "At-Takathur",
        arabicName: "التكاثر",
        totalAyahs: 8,
        juz: [{ number: 30, startAyah: 1, endAyah: 8 }]
    },
    {
        name: "Al-Asr",
        arabicName: "العصر",
        totalAyahs: 3,
        juz: [{ number: 30, startAyah: 1, endAyah: 3 }]
    },
    {
        name: "Al-Humazah",
        arabicName: "الهمزة",
        totalAyahs: 9,
        juz: [{ number: 30, startAyah: 1, endAyah: 9 }]
    },
    {
        name: "Al-Fil",
        arabicName: "الفيل",
        totalAyahs: 5,
        juz: [{ number: 30, startAyah: 1, endAyah: 5 }]
    },
    {
        name: "Quraysh",
        arabicName: "قريش",
        totalAyahs: 4,
        juz: [{ number: 30, startAyah: 1, endAyah: 4 }]
    },
    {
        name: "Al-Ma'un",
        arabicName: "الماعون",
        totalAyahs: 7,
        juz: [{ number: 30, startAyah: 1, endAyah: 7 }]
    },
    {
        name: "Al-Kawthar",
        arabicName: "الكوثر",
        totalAyahs: 3,
        juz: [{ number: 30, startAyah: 1, endAyah: 3 }]
    },
    {
        name: "Al-Kafiroon",
        arabicName: "الكافرون",
        totalAyahs: 6,
        juz: [{ number: 30, startAyah: 1, endAyah: 6 }]
    },
    {
        name: "An-Nasr",
        arabicName: "النصر",
        totalAyahs: 3,
        juz: [{ number: 30, startAyah: 1, endAyah: 3 }]
    },
    {
        name: "Al-Masad",
        arabicName: "المسد",
        totalAyahs: 5,
        juz: [{ number: 30, startAyah: 1, endAyah: 5 }]
    },
    {
        name: "Al-Ikhlas",
        arabicName: "الإخلاص",
        totalAyahs: 4,
        juz: [{ number: 30, startAyah: 1, endAyah: 4 }]
    },
    {
        name: "Al-Falaq",
        arabicName: "الفلق",
        totalAyahs: 5,
        juz: [{ number: 30, startAyah: 1, endAyah: 5 }]
    },
    {
        name: "An-Nas",
        arabicName: "الناس",
        totalAyahs: 6,
        juz: [{ number: 30, startAyah: 1, endAyah: 6 }]
    }


];
