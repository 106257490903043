import React from "react";
import { useAuth } from "../context/AuthContext";
import { FiLogOut, FiRefreshCw } from "react-icons/fi"; // Import icons

import "./Navbar.css";

const Navbar = () => {
    const { user, logout, role } = useAuth();

    const handleRefresh = () => {
        window.location.reload(); // Refresh the page
    };


    return (
        <nav className="navbar">
            {user && (
                <div className="navbar-content">
                    <div className="user-info">
                        <img
                            src={user.photoURL || `${process.env.PUBLIC_URL}/assets/profile-user.png`}
                            alt="User Avatar"
                            className="avatar"
                        />
                        <div className="user-details">
                            <span className="user-name">{user.displayName || "Anonymous"}</span>
                            <span
                                className={`user-role-badge ${user?.isAdmin ? "badge-admin" : role === "teacher" ? "badge-guru" : "badge-murid"
                                    }`}
                            >
                                {user?.isAdmin ? "Admin" : role === "teacher" ? "Guru" : "Murid"}
                            </span>

                        </div>
                    </div>

                    <div className="navbar-actions">
                        {/* Refresh Icon */}
                        <button onClick={handleRefresh} className="icon-button" title="Refresh">
                            <FiRefreshCw size={20} />
                        </button>

                        {/* Logout Icon */}
                        <button onClick={logout} className="icon-button" title="Logout">
                            <FiLogOut size={20} />
                        </button>
                    </div>
                </div>
            )}
        </nav>
    );
};

export default Navbar;
