import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDmrVwqdRt4nQpJU3B85oAmbdgXjyY08DQ",
    authDomain: "tasmik-com.firebaseapp.com",
    projectId: "tasmik-com",
    storageBucket: "tasmik-com.firebasestorage.app",
    messagingSenderId: "403131369440",
    appId: "1:403131369440:web:9bfac2a0d8c76fc6d4cd85"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
