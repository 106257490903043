// components/Footer.js
import React from "react";
import "./Footer.css"; // Ensure you style the footer in a separate CSS file

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/Logo-Tasmik-Rounded.png`}
                    alt="Tasmik Logo"
                    className="footer-logo"
                />
                <div className="footer-text">
                    Tasmik.com
                </div>            </div>
        </footer>
    );
};

export default Footer;
