import React, { createContext, useContext, useState } from "react";

const AppContext = createContext();

export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
    const [schools, setSchools] = useState([]);
    const [classrooms, setClassrooms] = useState([]);
    const [students, setStudents] = useState([]);
    const [approvedSchools, setApprovedSchools] = useState([]);
    const [adminSchools, setAdminSchools] = useState([]);
    const [approvedClassrooms, setApprovedClassrooms] = useState([]);

    // Example: Fetch schools (function can be reused across components)
    const fetchSchools = async () => {
        try {
            // Fetch schools logic
            console.log("Fetching schools...");
        } catch (error) {
            console.error("Error fetching schools:", error);
        }
    };

    return (
        <AppContext.Provider
            value={{
                schools,
                setSchools,
                classrooms,
                setClassrooms,
                students,
                setStudents,
                approvedSchools,
                setApprovedSchools,
                adminSchools,
                setAdminSchools,
                approvedClassrooms,
                setApprovedClassrooms,
                fetchSchools,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
