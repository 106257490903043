import React from "react";
import { Link } from "react-router-dom";

const RegistrationComplete = () => {
    return (
        <div style={{ textAlign: "center", marginTop: "50px" }}>
            <img
                src={`${process.env.PUBLIC_URL}/assets/check.png`}
                alt="Pendaftaran Berjaya"
                style={{ width: "100px", marginBottom: "20px" }}
            />
            <h1>Pendaftaran Berjaya!</h1>
            <p>Akaun anda sedang menunggu kelulusan.<br />Selepas diluluskan, anda boleh log masuk.</p>
            <Link to="/login">
                <button style={{ padding: "10px 20px", marginTop: "20px", fontSize: "16px" }}>
                    Utama
                </button>
            </Link>
        </div>
    );
};

export default RegistrationComplete;
