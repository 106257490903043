import React, { useEffect, useState } from "react";
import { surahData } from "../data/surahData";
import { saveStudentProgress, getTeacherByClassroom } from "../services/firestoreService";
import "./StudentDetails.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../services/firebase";
import Footer from "../components/Footer"; // Import Footer component


const StudentDetails = ({ student, onBack, onUpdate }) => {
    const [progressForm, setProgressForm] = useState({ endAyah: "" });
    const [status, setStatus] = useState("");
    const [teacherName, setTeacherName] = useState("");
    const [juz, setJuz] = useState("Juz 1");
    const [lastProgress, setLastProgress] = useState(null);
    const [schoolName, setSchoolName] = useState("Unknown School");
    const [classroomName, setClassroomName] = useState("Unknown Classroom");



    const fetchInitialData = async () => {
        console.log("Fetching initial data for student:", student);

        // Fetch teacher name
        if (student.classroomId) {
            try {
                const classroomRef = doc(db, "classrooms", student.classroomId);
                const classroomSnap = await getDoc(classroomRef);

                if (classroomSnap.exists()) {
                    const classroomData = classroomSnap.data();
                    setClassroomName(classroomData.name || "Unknown Classroom");

                    const teacherId = classroomData.teacherId;
                    if (teacherId) {
                        const teacher = await getTeacherByClassroom(teacherId);
                        setTeacherName(teacher?.name || "Unknown Teacher");
                    }
                }
            } catch (error) {
                console.error("Error fetching classroom data:", error);
            }
        }

        // Fetch school name
        if (student.schoolId) {
            try {
                const schoolRef = doc(db, "schools", student.schoolId);
                const schoolSnap = await getDoc(schoolRef);

                if (schoolSnap.exists()) {
                    setSchoolName(schoolSnap.data().name || "Unknown School");
                }
            } catch (error) {
                console.error("Error fetching school data:", error);
            }
        }

        // Handle student progress (already implemented)
        if (student.progress?.length > 0) {
            const lastProgress = student.progress[student.progress.length - 1];
            const surah = surahData.find((s) => s.name === lastProgress.surah);

            if (surah) {
                const matchingJuz = surah.juz.find(
                    (j) => lastProgress.endAyah >= j.startAyah && lastProgress.endAyah <= j.endAyah
                );
                setJuz(matchingJuz ? `Juz ${matchingJuz.number}` : "Unknown");
                setLastProgress(lastProgress);
            }
        }
    };



    useEffect(() => {
        if (student) {
            console.log("Student data updated:", student);
            fetchInitialData();
        }
    }, [student]);



    useEffect(() => {


        fetchInitialData();
    }, [student]);

    // Add refreshStudentData here
    const refreshStudentData = async () => {
        try {
            const studentRef = doc(db, "students", student.id);
            const studentSnap = await getDoc(studentRef);
            if (studentSnap.exists()) {
                const updatedStudent = studentSnap.data();
                student.progress = updatedStudent.progress || []; // Update student object with latest data
                fetchInitialData(); // Re-run the initialization logic
            }
        } catch (error) {
            console.error("Error refreshing student data:", error);
        }
    };

    const handleBack = async () => {
        console.log("Refreshing student data before navigating back...");
        await refreshStudentData(); // Ensure latest data
        onBack(student); // Pass the updated student to the parent
    };


    const handleSaveProgress = async (e) => {
        e.preventDefault();
        const endAyah = Number(e.target.elements.endAyah.value);
        const ulasan = progressForm.ulasan?.trim() || "Bacaan yang baik"; // Default to "Bacaan yang baik" if empty

        if (!endAyah || endAyah < student.startAyah || endAyah > surahData[student.currentSurahIndex].totalAyahs) {
            setStatus(`Ayat tidak sah! Masukkan antara ${student.startAyah} dan ${surahData[student.currentSurahIndex].totalAyahs}.`);
            return;
        }

        const progress = {
            surah: surahData[student.currentSurahIndex].name,
            startAyah: student.startAyah,
            endAyah,
            ulasan,
            date: new Date().toLocaleDateString(),
            time: new Date().toLocaleTimeString(),
        };

        try {
            await saveStudentProgress(student.id, progress);

            const nextAyah = endAyah + 1;
            const isSurahComplete = nextAyah > surahData[student.currentSurahIndex].totalAyahs;

            // Update local state
            student.currentSurahIndex = isSurahComplete ? student.currentSurahIndex + 1 : student.currentSurahIndex;
            student.startAyah = isSurahComplete ? 1 : nextAyah;

            // Ensure progress is initialized and push the new progress
            if (!student.progress) {
                student.progress = [];
            }
            student.progress.push(progress);

            // Calculate and update the new Juz
            const surah = surahData.find((s) => s.name === progress.surah);
            const matchingJuz = surah?.juz.find(
                (j) => progress.endAyah >= j.startAyah && progress.endAyah <= j.endAyah
            );

            if (matchingJuz) {
                setJuz(`Juz ${matchingJuz.number}`);
            } else {
                setJuz("Unknown");
            }

            setLastProgress(progress);
            setProgressForm({ endAyah: "", ulasan: "" }); // Clear the form by resetting `endAyah`

            setStatus(`Bacaan direkod untuk ${student.name}!`);
            e.target.reset();

            // Propagate changes to the parent component
            if (onUpdate) {
                onUpdate(student); // Update student progress in the dashboard
            }
        } catch (error) {
            console.error("Gagal menyimpan kemajuan:", error);
            setStatus("Terdapat ralat. Sila cuba lagi.");
        }
    };





    return (
        <>
            <div className="student-details-container">
                <div className="student-avatar-container">
                    <img
                        src={student.avatar || `${process.env.PUBLIC_URL}/assets/profile-user.png`}
                        alt={`${student.name}'s Avatar`}
                        className="student-avatar-detail"
                    />

                    <div>
                        <span className="name-student">{student.name}</span>
                        <br />
                        <div className="juz-badge">{juz}</div>
                    </div>

                </div>
                <div className="detail-student-school">
                    <strong>{schoolName}</strong>
                    {classroomName}
                    <br />
                    Guru Tasmik : Us {teacherName || "Unknown Teacher"}
                </div>



                <form onSubmit={handleSaveProgress} className="progress-form">
                    <div className="title-rekod-bacaan">Rekod Bacaan</div>
                    <div className="form-group">
                        Surah {surahData[student.currentSurahIndex]?.name || "Unknown Surah"}
                        <span className="arabic-name">
                            سورة {surahData[student.currentSurahIndex]?.arabicName || ""}
                        </span>
                    </div>
                    <div className="flex-container">
                        <div className="child-flex">
                            <label htmlFor="startAyah">dari ayat</label>
                            <input
                                type="number"
                                id="startAyah"
                                className="form-control-ayat-disable"
                                value={student.startAyah}
                                disabled
                            />
                        </div>

                        <div className="child-flex">
                            sehingga<br />
                            <input
                                type="number"
                                className="form-control-ayat"
                                name="endAyah"
                                placeholder=""
                                value={progressForm.endAyah}
                                onChange={(e) => setProgressForm({ ...progressForm, endAyah: e.target.value })}
                                required
                            />
                        </div>

                    </div>
                    <div className="form-group">
                        <label htmlFor="ulasan">Ulasan</label>
                        <textarea
                            id="ulasan"
                            className="form-control-ulasan"
                            value={progressForm.ulasan || ""}
                            onChange={(e) => setProgressForm({ ...progressForm, ulasan: e.target.value })}
                        ></textarea>
                    </div>
                    <div className="button-group">
                        <button onClick={onBack} className="back-button">
                            Kembali
                        </button>
                        <button type="submit" className="save-button">
                            Simpan
                        </button>
                    </div>
                </form>

                <div className="detail-student">
                    {lastProgress && (
                        <p className="student-detail-footnote">
                            Bacaan terakhir pada {lastProgress.date}, {lastProgress.time}
                            <br />
                            Surah {lastProgress.surah}, Ayat {lastProgress.startAyah} - {lastProgress.endAyah}
                        </p>
                    )}
                </div>
                {status && <p className="status-message">{status}</p>}

            </div>
            <Footer />
        </>

    );
};

export default StudentDetails;
