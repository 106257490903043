import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { AppProvider } from "./context/AppContext";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import LoadingScreen from "./components/LoadingScreen";
import Login from "./pages/Login";
import Register from "./pages/Register";
import TeacherDashboard from "./pages/TeacherDashboard";
import StudentDashboard from "./pages/StudentDashboard";
import RegistrationComplete from "./pages/RegistrationComplete";

const App = () => {
  const { user, role, approvedSchools, loading } = useAuth();
  const [isNavbarVisible, setNavbarVisibility] = useState(true);

  if (loading) {
    return <LoadingScreen />;
  }

  const renderRoutes = () => {
    if (!user) {
      // If the user is not logged in, show the login and register pages
      return (
        <>
          <Route path="/registration-complete" element={<RegistrationComplete />} />

          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </>
      );
    }

    if (role === "teacher" && approvedSchools.length > 0) {
      // Redirect teacher with approved schools to TeacherDashboard
      return (
        <>
          <Route
            path="/teacher/dashboard"
            element={<TeacherDashboard setNavbarVisibility={setNavbarVisibility} />}
          />
          <Route path="*" element={<Navigate to="/teacher/dashboard" replace />} />
        </>
      );
    }

    if (role === "teacher" && approvedSchools.length === 0) {
      // Show approval pending message for teachers with no approved schools
      return (
        <Route
          path="*"
          element={<h2>Approval Pending: You have no approved schools yet. Please contact your admin.</h2>}
        />
      );
    }

    if (role === "student") {
      // Redirect student to StudentDashboard
      return (
        <>
          <Route path="/student/dashboard" element={<StudentDashboard />} />
          <Route path="*" element={<Navigate to="/student/dashboard" replace />} />
        </>
      );
    }

    // Default fallback for unrecognized roles
    return <Route path="*" element={<h2>404 Not Found</h2>} />;
  };

  return (
    <AuthProvider>
      <AppProvider>
        <Router>
          {user && isNavbarVisible && <Navbar />}
          <Routes>{renderRoutes()}</Routes>
          {user && <Footer />}
        </Router>
      </AppProvider>
    </AuthProvider>
  );
};

export default App;
