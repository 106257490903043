import React from "react";
import { useAuth } from "../context/AuthContext";

const StudentDashboard = () => {
    const { user, studentData, isApproved } = useAuth();

    if (!user || !studentData) {
        return <p style={{ color: "red" }}>No user data found.</p>;
    }

    return (
        <div>
            <h1>Student Dashboard</h1>
            <p><strong>Name:</strong> {studentData.name}</p>
            <p><strong>Email:</strong> {studentData.email}</p>
            <p><strong>School:</strong> {studentData.schoolId}</p>
            <p><strong>Classroom:</strong> {studentData.classroomId}</p>
            <p><strong>Approval Status:</strong> {isApproved ? "Approved" : "Pending Approval"}</p>
        </div>
    );
};

export default StudentDashboard;
