import {
    collection,
    addDoc,
    getDocs,
    updateDoc,
    doc,
    query,
    where,
    arrayUnion,
    arrayRemove,
    setDoc,
    getDoc,
} from "firebase/firestore";
import { db } from "./firebase"; // Firebase instance

const sanitizeName = (name) => {
    return name
        .trim()
        .replace(/[.#$/[\]]/g, "") // Remove invalid Firestore characters
        .replace(/\s+/g, "_") // Replace spaces with underscores
        .toLowerCase(); // Convert to lowercase for consistency
};

export const saveStudentProgress = async (studentId, progress) => {
    try {
        const studentRef = doc(db, "students", studentId);
        const studentSnap = await getDoc(studentRef);

        if (studentSnap.exists()) {
            const existingProgress = studentSnap.data().progress || [];
            const updatedProgress = [...existingProgress, progress];

            await updateDoc(studentRef, {
                progress: updatedProgress, // Append new progress to the history
            });
        } else {
            await setDoc(studentRef, {
                progress: [progress], // Create a new progress array if none exists
            });
        }

        console.log("Student progress saved successfully.");
    } catch (error) {
        console.error("Error saving student progress:", error);
        throw new Error("Failed to save student progress.");
    }
};


// Add a new school
export const addSchool = async (schoolName, adminId) => {
    try {
        const schoolRef = collection(db, "schools");
        const newSchool = await addDoc(schoolRef, {
            name: schoolName,
            admin: adminId, // Assign admin
            teachers: [adminId], // Admin is the first teacher by default
            createdBy: adminId, // Keep track of creator
        });
        return { id: newSchool.id, name: schoolName };
    } catch (err) {
        console.error("Error adding school:", err);
        throw err;
    }
};

// Get all schools
export const getSchools = async () => {
    try {
        const schoolsRef = collection(db, "schools");
        const querySnapshot = await getDocs(schoolsRef);
        return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    } catch (err) {
        console.error("Error fetching schools:", err);
        throw err;
    }
};

export const addTeacherToSchool = async (authUid, teacherName, email, schoolIds) => {
    try {
        const teacherRef = doc(db, "teachers", authUid); // Use authUid as the document ID
        const schoolDetails = schoolIds.map((schoolId) => ({
            schoolId,
            isAdmin: false, // Default to false; can be updated later
        }));

        await setDoc(teacherRef, {
            name: teacherName,
            email,
            role: "teacher",
            isApproved: false,
            schools: schoolDetails, // Store school details including `isAdmin`
        });
        console.log("Teacher successfully added:", authUid);
    } catch (err) {
        console.error("Error adding teacher:", err);
        throw err;
    }
};


export const fetchSchools = async () => {
    try {
        const schoolsRef = collection(db, "schools");
        const querySnapshot = await getDocs(schoolsRef);
        const schools = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
        }));
        console.log("Fetched Schools:", schools); // Log all fetched schools
        return schools;
    } catch (err) {
        console.error("Error fetching schools:", err);
        throw err;
    }
};





// Remove a teacher from a school
export const removeTeacherFromSchool = async (teacherId, schoolId) => {
    try {
        const schoolRef = doc(db, "schools", schoolId);
        await updateDoc(schoolRef, {
            teachers: arrayRemove(teacherId),
        });
    } catch (err) {
        console.error("Error removing teacher from school:", err);
        throw err;
    }
};

export const getTeachersForSchool = async (schoolId) => {
    try {
        const teacherRef = collection(db, "teachers");
        const querySnapshot = await getDocs(teacherRef);

        const teachers = querySnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((teacher) =>
                teacher.schools.some(
                    (school) => school.schoolId === schoolId && school.isApproved
                )
            );

        return teachers;
    } catch (err) {
        console.error("Error fetching teachers for school:", err);
        throw err;
    }
};



export const addClassroom = async (classroomName, schoolId, teacherId) => {
    try {
        // Sanitize classroom name
        const sanitizedClassroomName = sanitizeName(classroomName);

        // Combine school ID with sanitized classroom name
        const classroomId = `${schoolId}-${sanitizedClassroomName}`; // Example: MRSMKB-2_rebah
        const classroomRef = doc(db, "classrooms", classroomId); // Use the combined name as ID

        // Check if the classroom already exists
        const classroomSnap = await getDoc(classroomRef);
        if (classroomSnap.exists()) {
            throw new Error(`Classroom "${classroomName}" already exists in school "${schoolId}".`);
        }

        // Add the classroom to Firestore
        await setDoc(classroomRef, {
            id: classroomId, // Store the combined UID
            name: classroomName, // Store the original classroom name
            schoolId, // Associate with the school
            teacherId, // Associate with the teacher who created it
            students: [], // Initialize with no students
        });

        return { id: classroomId, name: classroomName };
    } catch (error) {
        console.error("Error adding classroom:", error);
        throw error;
    }
};

export const getClassroomsForSchool = async (schoolId) => {
    console.log("Fetching classrooms for school ID:", schoolId);

    const classroomsRef = collection(db, "classrooms");
    const classroomsSnapshot = await getDocs(classroomsRef);
    const classrooms = [];

    for (const classroom of classroomsSnapshot.docs) {
        const classroomData = classroom.data();

        console.log(`Processing classroom: ${classroom.id}, Data:`, classroomData);

        if (classroomData.schoolId === schoolId) {
            let teacherName = "Unknown";

            if (classroomData.teacherId) {
                console.log(`Fetching teacher for TeacherId: ${classroomData.teacherId}`);

                const teacherQuery = query(
                    collection(db, "teachers"),
                    where("authUid", "==", classroomData.teacherId)
                );
                const teacherSnapshot = await getDocs(teacherQuery);

                if (!teacherSnapshot.empty) {
                    teacherName = teacherSnapshot.docs[0].data().name;
                    console.log(`Teacher Found: ${teacherName}`);
                } else {
                    console.log(`No teacher found for TeacherId: ${classroomData.teacherId}`);
                }
            }

            classrooms.push({
                id: classroom.id,
                name: classroomData.name,
                teacherName,
                teacherId: classroomData.teacherId, // Include teacherId for filtering
            });
        }
    }

    console.log("Final Classrooms List:", classrooms);
    return classrooms;
};




export const addStudentToClassroom = async (studentName, classroomId, schoolId, email, authUid) => {
    try {
        const sanitizedStudentName = sanitizeName(studentName);
        const studentId = `${schoolId}-${classroomId}-${sanitizedStudentName}`;

        const classroomRef = doc(db, "classrooms", classroomId);
        await updateDoc(classroomRef, {
            pendingStudents: arrayUnion({
                studentId,
                name: studentName,
                email,
            }),
        });

        const studentRef = doc(db, "students", studentId);
        await setDoc(studentRef, {
            id: studentId,
            name: studentName,
            email,
            authUid,
            schoolId,
            classroomApprovals: [
                { classroomId, isApproved: false },
            ],
        });

        console.log(`Student "${studentName}" added to classroom "${classroomId}".`);
    } catch (error) {
        console.error("Error adding student to classroom:", error);
        throw error;
    }
};



export const getApprovedStudentsForClassroom = async (classroomId) => {
    try {
        const studentsRef = collection(db, "students");
        const studentsSnap = await getDocs(studentsRef);

        console.log("Students Data:", studentsSnap.docs.map((doc) => doc.data()));

        const querySnapshot = await getDocs(studentsRef);

        const approvedStudents = querySnapshot.docs
            .map((doc) => doc.data())
            .filter((student) =>
                student.classroomApprovals.some(
                    (approval) => approval.classroomId === classroomId && approval.isApproved
                )
            );

        console.log("Approved students for classroom:", classroomId, approvedStudents);
        return approvedStudents;
    } catch (err) {
        console.error("Error fetching approved students for classroom:", err);
        throw err;
    }
};


export const getTeacherByClassroom = async (authUid) => {
    try {
        console.log("Fetching teacher by authUid:", authUid); // Debug log
        const teachersRef = collection(db, "teachers");
        const q = query(teachersRef, where("authUid", "==", authUid)); // Query by authUid
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
            const teacherData = querySnapshot.docs[0].data(); // Get the first matched document
            console.log("Fetched teacher data:", teacherData); // Debug log
            return teacherData;
        } else {
            console.error("No teacher found with the given authUid.");
            return null;
        }
    } catch (error) {
        console.error("Error fetching teacher:", error);
        throw error;
    }
};





// Remove a student from a classroom
export const removeStudentFromClassroom = async (studentId, classroomId) => {
    try {
        const classroomRef = doc(db, "classrooms", classroomId);
        await updateDoc(classroomRef, {
            students: arrayRemove(studentId),
        });
    } catch (err) {
        console.error("Error removing student from classroom:", err);
        throw err;
    }
};

export const getStudentsForClassroom = async (classroomId) => {
    try {
        const studentsRef = collection(db, "students");
        const querySnapshot = await getDocs(studentsRef);

        const approvedStudents = querySnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() })) // Include all fields
            .filter((student) =>
                (student.classroomApprovals || []).some(
                    (approval) => approval.classroomId === classroomId && approval.isApproved
                )
            );

        console.log("Approved Students for Classroom:", classroomId, approvedStudents);
        return approvedStudents;
    } catch (error) {
        console.error("Error fetching approved students for classroom:", error);
        throw error;
    }
};



export const approveTeacher = async (teacherId, schoolId) => {
    try {
        const teacherRef = doc(db, "teachers", teacherId);
        const teacherSnap = await getDoc(teacherRef);

        if (!teacherSnap.exists()) {
            throw new Error(`Teacher with ID "${teacherId}" not found.`);
        }

        const teacherData = teacherSnap.data();

        // Update the `isApproved` flag for the specific school
        const updatedSchools = teacherData.schools.map((school) =>
            school.schoolId === schoolId
                ? { ...school, isApproved: true }
                : school
        );

        await updateDoc(teacherRef, {
            schools: updatedSchools,
        });

        console.log(`Teacher "${teacherId}" approved for school "${schoolId}".`);
    } catch (err) {
        console.error("Error approving teacher:", err);
        throw err;
    }
};

export const approveStudent = async (studentId, classroomId) => {
    try {
        const studentRef = doc(db, "students", studentId);
        const studentSnap = await getDoc(studentRef);

        if (!studentSnap.exists()) {
            throw new Error(`Student with ID "${studentId}" not found.`);
        }

        const studentData = studentSnap.data();

        // Update the isApproved flag in classroomApprovals
        const updatedClassroomApprovals = studentData.classroomApprovals.map((approval) =>
            approval.classroomId === classroomId
                ? { ...approval, isApproved: true }
                : approval
        );

        await updateDoc(studentRef, {
            classroomApprovals: updatedClassroomApprovals,
        });

        // Add student ID to the classroom's students array
        const classroomRef = doc(db, "classrooms", classroomId);
        await updateDoc(classroomRef, {
            students: arrayUnion(studentId), // Add studentId to students array
            pendingStudents: arrayRemove({
                studentId: studentId, // Remove the student from pendingStudents
                name: studentData.name,
                email: studentData.email,
            }),
        });

        console.log(`Student "${studentId}" approved for classroom "${classroomId}".`);
    } catch (err) {
        console.error("Error approving student:", err);
        throw err;
    }
};



export const assignAdminToSchool = async (teacherId, schoolId) => {
    try {
        const teacherRef = doc(db, "teachers", teacherId);
        const teacherSnap = await getDoc(teacherRef);

        if (!teacherSnap.exists()) {
            throw new Error(`Teacher with ID "${teacherId}" not found.`);
        }

        const teacherData = teacherSnap.data();

        // Ensure the teacher is associated with the school
        const updatedSchools = teacherData.schools.map((school) =>
            school.schoolId === schoolId
                ? { ...school, isAdmin: true } // Update the `isAdmin` flag
                : school
        );

        await updateDoc(teacherRef, {
            schools: updatedSchools,
        });

        console.log(`Teacher "${teacherId}" is now an admin for school "${schoolId}".`);
    } catch (error) {
        console.error("Error assigning admin role:", error);
        throw error;
    }
};


export const getPendingTeachers = async (schoolId) => {
    try {
        const teacherRef = collection(db, "teachers");
        const querySnapshot = await getDocs(teacherRef);

        const pendingTeachers = querySnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((teacher) =>
                teacher.schools.some(
                    (school) => school.schoolId === schoolId && !school.isApproved
                )
            );

        return pendingTeachers;
    } catch (error) {
        console.error("Error fetching pending teachers:", error);
        throw error;
    }
};

export const getPendingStudents = async (classroomId) => {
    try {
        const studentsRef = collection(db, "students");
        const querySnapshot = await getDocs(studentsRef);

        // Map and filter students with classroomApprovals
        const pendingStudents = querySnapshot.docs
            .map((doc) => ({ id: doc.id, ...doc.data() }))
            .filter((student) =>
                (student.classroomApprovals || []).some(
                    (approval) =>
                        approval.classroomId === classroomId && approval.isApproved === false
                )
            );

        console.log("Pending Students for Classroom:", classroomId, pendingStudents);
        return pendingStudents;
    } catch (error) {
        console.error("Error fetching pending students:", error);
        throw error;
    }
};



