import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate for navigation
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { getDocs, query, where, collection } from "firebase/firestore";
import Lottie from "react-lottie";
import "../App.css";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate(); // Use navigate for redirection
    const [animationData, setAnimationData] = useState(null);

    useEffect(() => {
        fetch(`${process.env.PUBLIC_URL}/assets/quran02.json`)
            .then((response) => response.json())
            .then((data) => setAnimationData(data));
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(""); // Reset error

        try {
            // Sign in the user
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            console.log("Logged-in User UID:", user.uid);

            // Query Firestore for the user's role and approval status
            const teachersRef = collection(db, "teachers");
            const teacherQuery = query(teachersRef, where("authUid", "==", user.uid));
            const teacherSnap = await getDocs(teacherQuery);

            console.log("Teacher Query Snapshot:", teacherSnap.empty ? "No Data" : "Data Found");

            if (!teacherSnap.empty) {
                // User is a teacher
                const teacherData = teacherSnap.docs[0].data();
                console.log("Fetched Teacher Data:", teacherData);

                // Filter schools where `isApproved` is true
                const approvedSchools = teacherData.schools.filter((school) => school.isApproved);
                console.log("Approved Schools:", approvedSchools);

                if (approvedSchools.length > 0) {
                    console.log("Redirecting to Teacher Dashboard...");
                    navigate("/teacher/dashboard", { state: { user: teacherData, approvedSchools } });
                } else {
                    console.error("No approved schools found for this teacher.");
                    setError("Your account is pending approval. Please contact your administrator.");
                }
            } else {
                console.error("Teacher record not found in Firestore.");
                setError("You are not registered as a teacher. Please contact your school.");
            }
        } catch (err) {
            console.error("Login Error:", err);
            setError("Invalid email or password.");
        }
    };




    return (
        <div className="page-container">
            <header className="appbar">
                <img
                    src={`${process.env.PUBLIC_URL}/assets/Tasmik-white-01.png`}
                    alt="Tasmik Logo"
                    className="appbar-logo"
                />
            </header>

            <main className="main-content">
                <div className="login-container">
                    {animationData && (
                        <Lottie options={defaultOptions} height={110} width={110} />
                    )}
                    <form onSubmit={handleLogin} className="login-form">
                        <div className="input-container">
                            <input
                                type="email"
                                id="email"
                                placeholder=" "
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="input-field"
                                required
                            />
                            <label htmlFor="email" className="floating-label">
                                Emel
                            </label>
                        </div>
                        <div className="input-container">
                            <input
                                type="password"
                                id="password"
                                placeholder=" "
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="input-field"
                                required
                            />
                            <label htmlFor="password" className="floating-label">
                                Katalaluan
                            </label>
                        </div>
                        <button type="submit" className="login-button">
                            Teruskan
                        </button>
                    </form>

                    {error && <p className="error-message">{error}</p>}
                    <p>
                        Tiada akaun? <Link to="/register" className="link">Daftar</Link>
                    </p>
                </div>
            </main>

            <footer className="footer-login">
                <p>Rekod Bacaan Tasmik</p>
                <p>tasmik.com © {new Date().getFullYear()}</p>
            </footer>
        </div>
    );
};

export default Login;
