import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../services/firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../services/firebase";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [role, setRole] = useState(null); // "admin", "teacher", or "student"
    const [approvedSchools, setApprovedSchools] = useState([]); // Approved schools for teachers
    const [adminSchools, setAdminSchools] = useState([]); // Schools where the teacher is an admin
    const [approvedClassrooms, setApprovedClassrooms] = useState([]); // Approved classrooms for students
    const [loading, setLoading] = useState(true); // Loading state
    const [pendingTeachers, setPendingTeachers] = useState([]); // Pending teachers for admin
    const [avatar, setAvatar] = useState(null); // Avatar URL or default image

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            console.log("Auth State Changed. Current User:", currentUser); // Log when the auth state changes
            setLoading(true);


            if (!currentUser) {
                console.log("No user is logged in.");
                resetAuthData();
                setLoading(false);
                return;
            }

            console.log(`Fetching data for user UID: ${currentUser.uid}`);

            try {
                // Fetch teacher data
                const teachersRef = collection(db, "teachers");
                const teacherQuery = query(teachersRef, where("authUid", "==", currentUser.uid));
                const teacherSnap = await getDocs(teacherQuery);

                if (!teacherSnap.empty) {
                    const teacherData = teacherSnap.docs[0].data();
                    console.log("Fetched Teacher Data:", teacherData);

                    if (!teacherData.schools || teacherData.schools.length === 0) {
                        console.error("No schools found in teacher data.");
                        resetAuthData();
                        setLoading(false);
                        return;
                    }

                    // Filter approved and admin schools
                    const approvedSchools = teacherData.schools.filter((school) => school.isApproved);
                    const adminSchools = approvedSchools.filter((school) => school.isAdmin);

                    console.log("Approved Schools:", approvedSchools);
                    console.log("Admin Schools:", adminSchools);

                    if (approvedSchools.length === 0) {
                        console.error("No approved schools found. User cannot proceed.");
                        resetAuthData();
                        setLoading(false);
                        return;
                    }

                    // Update state
                    setRole("teacher");
                    setAvatar(teacherData.avatar || `${process.env.PUBLIC_URL}/assets/default-avatar.png`);

                    setUser({
                        ...currentUser,
                        authUid: currentUser.uid, // Ensure authUid is explicitly set
                        displayName: teacherData.name || currentUser.displayName,
                        schools: teacherData.schools,
                    });


                    setApprovedSchools(approvedSchools);
                    setAdminSchools(adminSchools);

                    // Fetch pending teachers if the user is an admin
                    if (adminSchools.length > 0) {
                        const pendingQuery = query(
                            teachersRef,
                            where("isApproved", "==", false),
                            where("schools", "array-contains-any", adminSchools.map((school) => school.schoolId))
                        );
                        const pendingSnap = await getDocs(pendingQuery);

                        const pendingTeachers = pendingSnap.docs.map((doc) => ({
                            id: doc.id,
                            ...doc.data(),
                        }));

                        console.log("Pending Teachers:", pendingTeachers);
                        setPendingTeachers(pendingTeachers);
                    }

                    setApprovedClassrooms([]); // Clear any student-specific data
                    setLoading(false);
                    return;
                }

                // Fetch student data
                const studentsRef = collection(db, "students");
                const studentQuery = query(studentsRef, where("authUid", "==", currentUser.uid));
                const studentSnap = await getDocs(studentQuery);

                if (!studentSnap.empty) {
                    const studentData = studentSnap.docs[0].data();
                    console.log("Fetched Student Data:", studentData);

                    // Filter approved classrooms
                    const approvedClassrooms = studentData.classroomApprovals?.filter(
                        (classroom) => classroom.isApproved
                    );

                    console.log("Approved Classrooms:", approvedClassrooms);

                    setRole("student");
                    setAvatar(studentData.avatar || `${process.env.PUBLIC_URL}/assets/default-avatar.png`);
                    setUser({
                        ...currentUser,
                        displayName: studentData.name || currentUser.displayName,
                    });
                    setApprovedClassrooms(approvedClassrooms);
                    setApprovedSchools([]); // Clear teacher-specific data
                    setAdminSchools([]);
                    setPendingTeachers([]);
                    setLoading(false);
                    return;
                }

                console.error("User not found in teachers or students. Logging out...");
                await signOut(auth);
                resetAuthData();
            } catch (error) {
                console.error("Error fetching user data:", error);
                resetAuthData();
            } finally {
                setLoading(false);
            }
        });

        return () => unsubscribe();
    }, []);

    const resetAuthData = () => {
        console.log("Resetting Authentication Data...");
        setUser(null);
        setRole(null);
        setApprovedSchools([]);
        setAdminSchools([]);
        setApprovedClassrooms([]);
        setPendingTeachers([]);
        setAvatar(null);
    };

    const logout = async () => {
        try {
            await signOut(auth);
            console.log("User logged out successfully.");
            resetAuthData();
        } catch (err) {
            console.error("Error during logout:", err);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                role,
                approvedSchools,
                adminSchools,
                approvedClassrooms,
                loading,
                pendingTeachers,
                avatar,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
