import React from "react";

const LoadingScreen = () => {
    return (
        <div
            style={{
                position: "fixed", // Ensure it stays at the top of all content
                top: 0,
                left: 0,
                height: "100vh", // Full viewport height
                width: "100vw", // Full viewport width
                backgroundColor: "black", // Black background
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999, // High z-index to stay above all other content
            }}
        >
            <img
                src={`${process.env.PUBLIC_URL}/assets/Tasmik-white-01.png`}
                alt="Loading..."
                style={{ width: "160px" }}
            />
        </div>
    );
};

export default LoadingScreen;
