import React, { useEffect, useState } from "react";
import {
    getClassroomsForSchool,
    getStudentsForClassroom,
    approveStudent,
    getPendingStudents,
    addClassroom,
    getPendingTeachers,
    approveTeacher,
    fetchSchools,
} from "../services/firestoreService";
import { useAuth } from "../context/AuthContext";
import "./Dashboard.css";
import { surahData } from "../data/surahData";
import StudentDetails from "./StudentDetails"; // Import StudentDetails.js
import LoadingScreen from "../components/LoadingScreen";



const TeacherDashboard = ({ setNavbarVisibility }) => {
    const [isLoading, setIsLoading] = useState(true); // Add a loading state

    const { user, adminSchools, } = useAuth();
    const [selectedSchool, setSelectedSchool] = useState(null);
    const [classrooms, setClassrooms] = useState([]);
    const [selectedClassroom, setSelectedClassroom] = useState(null);
    const [students, setStudents] = useState([]);
    const [pendingStudents, setPendingStudents] = useState([]);
    const [pendingTeachers, setPendingTeachers] = useState([]);
    const [newClassName, setNewClassName] = useState("");
    const [status, setStatus] = useState("");
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const [mappedSchools, setMappedSchools] = useState([]);

    const toggleFormVisibility = () => setIsFormVisible((prev) => !prev);

    // Consolidated function to fetch and filter classrooms
    const fetchClassrooms = async (schoolId) => {
        try {
            const data = await getClassroomsForSchool(schoolId);

            // Admins see all classrooms; teachers see only their assigned ones
            const filteredClassrooms = adminSchools.some((school) => school.schoolId === schoolId)
                ? data
                : data.filter((classroom) => classroom.teacherId === user.authUid);

            console.log("Filtered Classrooms:", filteredClassrooms);
            setClassrooms(filteredClassrooms);
            setIsLoading(false)
        } catch (error) {
            console.error("Error fetching classrooms:", error);
            setStatus("Failed to fetch classrooms.");
        }
    };

    // Fetch schools and map them with names
    useEffect(() => {
        const fetchAndMapSchools = async () => {
            if (!user?.schools) return;

            try {
                const allSchools = await fetchSchools();
                const updatedSchools = user.schools.map((userSchool) => {
                    const schoolDetails = allSchools.find((s) => s.id === userSchool.schoolId);
                    return {
                        ...userSchool,
                        name: schoolDetails?.name || "Unknown School",
                    };
                });

                console.log("Mapped Schools:", updatedSchools);
                setMappedSchools(updatedSchools);

                if (updatedSchools.length > 0 && !selectedSchool) {
                    const firstSchool = updatedSchools.find((school) => school.isApproved);
                    if (firstSchool) {
                        console.log("Auto-selecting first school:", firstSchool);
                        setSelectedSchool({ id: firstSchool.schoolId, name: firstSchool.name });
                    }
                }
            } catch (error) {
                console.error("Error mapping schools:", error);
            }
        };

        fetchAndMapSchools();
    }, [user]);

    // Fetch classrooms when the selected school changes
    useEffect(() => {
        if (selectedSchool) {
            console.log("Fetching classrooms for school:", selectedSchool.id);
            fetchClassrooms(selectedSchool.id);
        }
    }, [selectedSchool]);

    // Fetch pending teachers (only for admins)
    useEffect(() => {
        const fetchPendingTeachers = async () => {
            if (!selectedSchool || !adminSchools.some((school) => school.schoolId === selectedSchool.id)) return;

            try {
                const data = await getPendingTeachers(selectedSchool.id);
                console.log("Pending Teachers:", data);
                setPendingTeachers(data);
            } catch (error) {
                console.error("Error fetching pending teachers:", error);
                setStatus("Failed to fetch pending teachers.");
            }
        };

        fetchPendingTeachers();
    }, [selectedSchool, adminSchools]);

    useEffect(() => {
        if (!selectedClassroom) return;

        const fetchStudents = async () => {
            try {
                console.log("Fetching students for classroom:", selectedClassroom);

                const pending = await getPendingStudents(selectedClassroom);
                console.log("Pending Students:", pending);

                const approved = await getStudentsForClassroom(selectedClassroom);
                console.log("Approved Students (Raw):", approved);

                const filteredApproved = approved.filter((student) =>
                    student.classroomApprovals?.some(
                        (approval) => approval.classroomId === selectedClassroom && approval.isApproved
                    )
                );

                console.log("Filtered Approved Students:", filteredApproved);

                setPendingStudents(pending); // Ensure this state is being updated
                setStudents(filteredApproved); // Ensure this state is being updated
            } catch (error) {
                console.error("Error fetching students:", error);
                setStatus("Failed to fetch students.");
            }
        };


        fetchStudents();
    }, [selectedClassroom]);




    // Handlers
    const handleSelectSchool = (school) => {
        console.log("Selected School:", school);
        setSelectedSchool({
            id: school.schoolId,
            name: school.name, // Ensure the name is properly passed
        });
        setClassrooms([]);
        setSelectedClassroom(null);
        setStudents([]);
        setPendingStudents([]);
        setPendingTeachers([]);
    };

    const handleCreateClassroom = async (e) => {
        e.preventDefault();
        if (!newClassName.trim() || !selectedSchool) {
            setStatus("Please provide a classroom name and select a school.");
            return;
        }

        try {
            await addClassroom(newClassName, selectedSchool.id, user.authUid);
            setNewClassName("");
            setStatus(`Classroom "${newClassName}" created successfully!`);
            fetchClassrooms(selectedSchool.id); // Refresh classrooms
        } catch (error) {
            console.error("Error creating classroom:", error);
            setStatus("Error creating classroom. Please try again.");
        }
    };

    const handleStudentClick = (student) => {
        console.log("Student clicked:", student); // Ensure the student data is complete
        console.log("Student data passed to StudentDetails:", student);

        let currentSurahIndex = 0;
        let startAyah = 1;

        if (student.progress?.length > 0) {
            const lastProgress = student.progress[student.progress.length - 1];
            const surah = surahData.find((s) => s.name === lastProgress.surah);

            if (surah) {
                const nextAyah = lastProgress.endAyah + 1;
                if (nextAyah > surah.totalAyahs) {
                    currentSurahIndex = surahData.indexOf(surah) + 1;
                    startAyah = 1;
                } else {
                    currentSurahIndex = surahData.indexOf(surah);
                    startAyah = nextAyah;
                }
            }
        }

        // Pass all necessary data to `StudentDetails`
        setSelectedStudent({
            ...student,
            schoolId: selectedSchool?.id || "Unknown School",
            classroomId: selectedClassroom || "Unknown Classroom",
            currentSurahIndex,
            startAyah,
        });
        setNavbarVisibility(false);
    };


    const handleApproveTeacher = async (teacherId) => {
        try {
            await approveTeacher(teacherId, selectedSchool.id);
            setPendingTeachers((prev) => prev.filter((teacher) => teacher.id !== teacherId));
        } catch (error) {
            console.error("Error approving teacher:", error);
            setStatus("Failed to approve teacher.");
        }
    };

    const handleSelectClassroom = (classroomId) => {
        console.log("Selected Classroom:", classroomId);
        setSelectedClassroom(classroomId);
    };

    const handleApproveStudent = async (studentId) => {
        try {
            // Approve the student in Firestore
            await approveStudent(studentId, selectedClassroom);

            // Find the approved student's details in the pendingStudents array
            const approvedStudent = pendingStudents.find((student) => student.id === studentId);

            if (approvedStudent) {
                // Remove the student from pendingStudents
                setPendingStudents((prev) => prev.filter((student) => student.id !== studentId));

                // Add the student to the approved students list
                setStudents((prev) => [...prev, { ...approvedStudent, isApproved: true }]);
            }
        } catch (error) {
            console.error("Error approving student:", error);
            setStatus("Failed to approve student.");
        }
    };

    // Get the selected classroom's name
    const getSelectedClassroomName = () => {
        const selected = classrooms.find((classroom) => classroom.id === selectedClassroom);
        return selected ? selected.name : "Unknown";
    };

    useEffect(() => {
        console.log("Selected School:", selectedSchool);
        console.log("Selected School Name:", selectedSchool?.name);
        console.log("Approved Students:", students);
        console.log("Mapped Schools with Names:", user?.schools || []);

        console.log("Classrooms:", classrooms);
        console.log("Selected Classroom:", selectedClassroom);
        console.log("Students:", students);
        console.log("Pending Students:", pendingStudents);
    }, [selectedSchool, classrooms, selectedClassroom, students, pendingStudents]);


    useEffect(() => {
        if (selectedStudent) {
            console.log("Selected Student Details:", selectedStudent);
        }
    }, [selectedStudent]);

    const handleStudentBack = (updatedStudent) => {
        setSelectedStudent(null); // Close the StudentDetails view
        setNavbarVisibility(true); // Show the navbar again

        // Update the specific student's progress in the students list
        setStudents((prevStudents) =>
            prevStudents.map((student) =>
                student.id === updatedStudent.id ? updatedStudent : student
            )
        );
    };

    if (isLoading) {
        return <LoadingScreen />;
    }

    return (
        <div className="dashboard-container">
            {/* Schools Section */}
            <div className="dashboard-section-school">
                <div className="school-box-scroll-container">
                    {(mappedSchools || [])
                        .slice()
                        .sort((a, b) => b.isApproved - a.isApproved) // Approved schools first
                        .map((school) => (

                            <div
                                key={school.schoolId}
                                className={`school-box ${selectedSchool?.id === school.schoolId ? "active" : ""} ${school.isApproved ? "" : "disabled"
                                    }`}
                                onClick={school.isApproved ? () => handleSelectSchool(school) : null}
                            >
                                <img
                                    src={`${process.env.PUBLIC_URL}/assets/Logo_MRSM.png`}
                                    alt={`${school.name} Logo`}
                                    className="school-logo"
                                />
                                <div className="school-label">
                                    <span className="school-name">{school.name}</span>
                                    <span className="school-id">{school.schoolId}</span>
                                </div>
                                {school.isAdmin && <span className="admin-badge">Anda<br />Admin</span>}
                                {!school.isApproved && <span className="pending-label">Pending Approval</span>}
                            </div>
                        ))}

                </div>
            </div>


            {/* Classrooms Section */}
            {selectedSchool && (
                <div className="dashboard-section">
                    {adminSchools.some((school) => school.schoolId === selectedSchool.id) &&
                        pendingTeachers.length > 0 && (
                            <>
                                <h3>Permintaan menjadi guru tasmik</h3>
                                <ul>
                                    {pendingTeachers.map((teacher) => (
                                        <li key={teacher.id}>
                                            {teacher.name} ({teacher.email})
                                            <button onClick={() => handleApproveTeacher(teacher.id)}>Benarkan</button>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}

                    <div className="kelas-tasmik-header">
                        <h3>Kelas Tasmik</h3>
                        <button className="add-icon" onClick={toggleFormVisibility}>
                            {isFormVisible ? "×" : "+"}

                        </button>
                    </div>

                    {isFormVisible && (
                        <form onSubmit={handleCreateClassroom} className="classroom-form">
                            <input
                                type="text"
                                placeholder="Masukkan nama kelas"
                                value={newClassName}
                                onChange={(e) => setNewClassName(e.target.value)}
                                className="classroom-input"
                            />
                            <button type="submit" className="create-button">
                                Cipta
                            </button>
                        </form>
                    )}

                    {status && <p>{status}</p>}

                    {classrooms.length === 0 ? (
                        <div className="no-students-container">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/class.png`}
                                alt="No Students"
                                className="no-students-image"
                            />
                            <p className="no-students-message">Sila cipta kelas tasmik<br />pertama anda</p>
                        </div>
                    ) : (
                        <div className="school-box-scroll-container">
                            {classrooms.map((classroom) => (
                                <div
                                    key={classroom.id}
                                    className={`school-box ${selectedClassroom === classroom.id ? "active" : ""}`}
                                    onClick={() => handleSelectClassroom(classroom.id)}
                                >
                                    <p className="teacher-name">Guru : <br />{classroom.teacherName}</p>
                                    <h3 className="classroom-name">{classroom.name}</h3>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}


            {pendingStudents.length > 0 && (
                <div className="pending-container">
                    <h3>Permohonan masuk kelas</h3>
                    <ul>
                        {pendingStudents.map((student) => (
                            <li key={student.id}>
                                {student.name} ({student.email})
                                <button onClick={() => handleApproveStudent(student.id)}>Benarkan</button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}



            {/* Students Section */}
            {selectedStudent ? (
                // Render StudentDetails.js if a student is selected
                <div className="student-details-wrapper">
                    <StudentDetails
                        student={selectedStudent}
                        onBack={(updatedStudent) => {
                            setSelectedStudent(null); // Close the StudentDetails view
                            setNavbarVisibility(true); // Show the navbar
                            // Update the specific student's progress in the students list
                            setStudents((prevStudents) =>
                                prevStudents.map((student) =>
                                    student.id === updatedStudent.id ? updatedStudent : student
                                )
                            );
                        }}
                        onUpdate={(updatedStudent) => {
                            setStudents((prevStudents) =>
                                prevStudents.map((s) =>
                                    s.id === updatedStudent.id ? updatedStudent : s
                                )
                            );
                        }}
                    />

                </div>
            ) : (
                // Render student list if no student is selected
                selectedClassroom && (
                    <div className="dashboard-section">
                        <h3>Murid {getSelectedClassroomName()}</h3>
                        <div className="students-list">
                            {students.length > 0 ? (
                                students.map((student) => (
                                    <div
                                        key={student.id}
                                        className="student-container"
                                        onClick={() => handleStudentClick(student)} // Use the handleStudentClick function
                                    >
                                        <div className="student-title">
                                            <img
                                                src={student.avatar || `${process.env.PUBLIC_URL}/assets/profile-user.png`}
                                                alt={`${student.name}'s Avatar`}
                                                className="student-avatar"
                                            />
                                            <div className="student-details">
                                                <h4 className="student-name">{student.name}</h4>

                                            </div>
                                        </div>
                                        <div className="juz-badge-dashboard">
                                            {student.progress?.length > 0
                                                ? `Juz ${surahData.find(
                                                    (s) => s.name === student.progress.at(-1).surah
                                                )?.juz.find(
                                                    (j) =>
                                                        student.progress.at(-1).endAyah >= j.startAyah &&
                                                        student.progress.at(-1).endAyah <= j.endAyah
                                                )?.number || "Unknown"
                                                }`
                                                : "Juz 1"}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="no-students-container">
                                    <img
                                        src={`${process.env.PUBLIC_URL}/assets/empty-folder.png`}
                                        alt="No Students"
                                        className="no-students-image"
                                    />
                                    <p className="no-students-message">Tiada murid</p>
                                </div>
                            )}

                        </div>

                    </div>
                )
            )}




        </div>
    );
};

export default TeacherDashboard;
