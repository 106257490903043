import React, { useEffect, useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { auth } from "../services/firebase";
import { db } from "../services/firebase";
import { getSchools, getClassroomsForSchool } from "../services/firestoreService";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

const Register = () => {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("teacher");
    const [schools, setSchools] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedSchools, setSelectedSchools] = useState([]);
    const [selectedSchool, setSelectedSchool] = useState("");
    const [selectedClass, setSelectedClass] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    // Handle multi-select for teachers
    const handleTeacherSchoolChange = (selectedOptions) => {
        setSelectedSchools(selectedOptions.map((option) => option.value)); // Store IDs only
    };

    // Handle single-select for student school
    const handleStudentSchoolChange = (selectedOption) => {
        setSelectedSchool(selectedOption ? selectedOption.value : ""); // Store the selected ID
        setSelectedClass(""); // Reset class when school changes
    };

    // Handle single-select for student class
    const handleStudentClassChange = (selectedOption) => {
        setSelectedClass(selectedOption ? selectedOption.value : "");
    };

    // Map school options for React Select
    const schoolOptions = schools.map((school) => ({
        value: school.id,
        label: `${school.id}`,
    }));

    // Map class options for React Select
    const classOptions = classes.map((cls) => ({
        value: cls.id,
        label: cls.name,
    }));

    // React Select custom styles
    const customStyles = {
        control: (base, state) => ({
            ...base,
            borderRadius: "8px",
            borderColor: state.isFocused ? "#000" : "#ddd",
            boxShadow: state.isFocused ? "0 0 0 1px #000" : "none",
            padding: "5px",
        }),
        placeholder: (base) => ({
            ...base,
            textAlign: "left",
            color: "#aaa",
        }),
        menu: (base) => ({
            ...base,
            textAlign: "left",
        }),
        option: (base, state) => ({
            ...base,
            textAlign: "left",
            backgroundColor: state.isFocused ? "#f5f5f5" : "white",
            color: "#333",
        }),
    };

    // Fetch schools on component mount
    useEffect(() => {
        const fetchSchools = async () => {
            try {
                const schoolData = await getSchools();
                setSchools(schoolData);
            } catch (err) {
                setError("Could not fetch schools.");
            }
        };
        fetchSchools();
    }, []);

    // Fetch classes when a school is selected
    useEffect(() => {
        if (!selectedSchool) {
            setClasses([]);
            return;
        }
        const fetchClasses = async () => {
            try {
                const classData = await getClassroomsForSchool(selectedSchool);
                setClasses(classData);
            } catch (err) {
                setError("Could not fetch classes.");
            }
        };
        fetchClasses();
    }, [selectedSchool]);

    // Handle registration
    const handleRegister = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading
        setError(""); // Clear any previous error

        // Input validation
        if (!name.trim()) {
            setError("Name is required.");
            setLoading(false); // Stop loading in case of validation error
            return;
        }
        if (role === "teacher" && selectedSchools.length === 0) {
            setError("Please select at least one school.");
            setLoading(false); // Stop loading
            return;
        }
        if (role === "student" && (!selectedSchool || !selectedClass)) {
            setError("Please select both school and class.");
            setLoading(false); // Stop loading
            return;
        }

        try {
            // Create user in Firebase Auth
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            const userId = userCredential.user.uid;

            if (role === "teacher") {
                // Prepare school details with default approval and admin status
                const schoolDetails = selectedSchools.map((schoolId) => ({
                    schoolId,
                    isAdmin: false, // Admin status to be updated manually
                    isApproved: false, // Pending approval
                }));

                // Save teacher to Firestore
                await setDoc(doc(db, "teachers", userId), {
                    authUid: userId,
                    name: name.trim(),
                    email: email,
                    role: "teacher",
                    schools: schoolDetails,
                    avatar: null, // Optional
                });
            } else if (role === "student") {
                // Save student to Firestore with default classroom approval
                const classroomApproval = {
                    classroomId: selectedClass,
                    isApproved: false, // Pending teacher approval
                };

                await setDoc(doc(db, "students", userId), {
                    authUid: userId,
                    name: name.trim(),
                    email: email,
                    role: "student",
                    classroomApprovals: [classroomApproval],
                    avatar: null, // Optional
                });

                // Add student to the pendingStudents array in the classroom document
                const classroomRef = doc(db, "classrooms", selectedClass);
                await updateDoc(classroomRef, {
                    pendingStudents: arrayUnion({
                        studentId: userId,
                        name: name.trim(),
                        email: email,
                    }),
                });
            }

            setSuccess(
                "Registration successful! Your account and classroom/school access are pending approval."
            );

            // Navigate to registration complete page
            navigate("/registration-complete");

            // Clear form inputs and state
            resetForm();
        } catch (err) {
            const errorMessage = err.message.includes("auth/email-already-in-use")
                ? "Email already in use. Please try logging in."
                : err.message.includes("auth/weak-password")
                    ? "Password should be at least 6 characters."
                    : "Registration failed. Please try again.";
            setError(errorMessage);
        } finally {
            setLoading(false); // Ensure loading stops
        }
    };


    const resetForm = () => {
        setName("");
        setEmail("");
        setPassword("");
        setRole("teacher");
        setSelectedSchools([]);
        setSelectedSchool("");
        setSelectedClass("");
        setError("");
    };

    return (
        <div className="login-container">
            {loading ? (
                <div style={{ textAlign: "center", marginTop: "50px" }}>
                    <p>Loading...</p>
                </div>
            ) : (
                <>
                    <h1>Daftar</h1>

                    {error && <p className="error-message">{error}</p>}
                    {success && <p style={{ color: "green" }}>{success}</p>}

                    <div className="role-selection-container">
                        <div
                            className={`option-container ${role === "teacher" ? "active" : ""}`}
                            onClick={() => setRole("teacher")}
                        >
                            <h2>Guru</h2>
                            <p>Uruskan sekolah dan kelas anda sebagai seorang guru tasmik</p>
                        </div>
                        <div
                            className={`option-container ${role === "student" ? "active" : ""}`}
                            onClick={() => setRole("student")}
                        >
                            <h2>Murid</h2>
                            <p>Sertai kelas anda untuk tasmik sebagai seorang murid</p>
                        </div>
                    </div>

                    <form onSubmit={handleRegister} className="login-form">
                        <div className="input-container">
                            <input
                                type="text"
                                placeholder="Nama"
                                className="input-field"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="input-container">
                            <input
                                type="email"
                                placeholder="Emel"
                                className="input-field"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>

                        <div className="input-container">
                            <input
                                type="password"
                                placeholder="Katalaluan"
                                className="input-field"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </div>

                        {role === "teacher" && (
                            <div className="input-container">
                                <Select
                                    isMulti
                                    options={schoolOptions}
                                    onChange={handleTeacherSchoolChange}
                                    value={schoolOptions.filter((option) =>
                                        selectedSchools.includes(option.value)
                                    )}
                                    placeholder="Cari dan pilih sekolah..."
                                    styles={customStyles}
                                />
                            </div>
                        )}

                        {role === "student" && (
                            <div className="input-container">
                                <div>
                                    <Select
                                        options={schoolOptions}
                                        onChange={handleStudentSchoolChange}
                                        value={schoolOptions.find((option) => option.value === selectedSchool)}
                                        placeholder="Pilih sekolah"
                                        isClearable
                                        styles={customStyles}
                                    />
                                </div>
                                <br />
                                <div>
                                    <Select
                                        options={classOptions}
                                        onChange={handleStudentClassChange}
                                        value={classOptions.find((option) => option.value === selectedClass)}
                                        placeholder="Pilih kelas"
                                        isClearable
                                        isDisabled={!selectedSchool}
                                        styles={customStyles}
                                    />
                                </div>
                            </div>
                        )}

                        <button type="submit" className="login-button">
                            {role === "teacher" ? "Daftar sebagai Guru" : "Daftar sebagai Murid"}
                        </button>
                    </form>

                    <div>
                        <Link to="/login" className="link">
                            Kembali ke Utama
                        </Link>
                    </div>
                </>
            )}
        </div>
    );

};

export default Register;
